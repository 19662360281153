import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ArrowTypes } from './types';

export const MusicDetail = styled(motion.div)`
  padding: 1rem 2rem;
  @media (min-width: 768px) {
    padding: 0;
    // width: 20rem;
  }
`;

export const MusicImage = styled(motion.a)`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  // overflow: hidden;
  width: 275px;
  height: 275px;
  object-fit: cover;

  :hover img {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  @media (min-width: 768px) {
    align-items: center;
    // display: block;
    width: 450px;
    height: 450px;
    // height: 100%;
  }
`;

export const Arrow = styled.button<ArrowTypes>`
  right: ${(props) => props.right && '41%'};
  left: ${(props) => props.left && '41%'};
  position: absolute;
  z-index: 2;
  top: 40%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-weight: 100;
  font-size: 2rem;
  outline: none;
  color: white;
  :focus {
    outline: none;
  }

  @media (min-width: 768px) {
    display: block;
    right: ${(props) => props.right && props.right};
    left: ${(props) => props.left && props.left};
    font-size: 3rem;
  }
`;

export const SlideItem = styled.a`
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
