import { Box } from "../../components/StyledComponent";
import { useRef } from "react";
// import $ from "jquery";
import styled from "styled-components";
import POSTER from "../../assets/images/hero+mobile.gif";
import VIDEO_D from "../../assets/videos/hero desktop.mov";
import VIDEO_M from "../../assets/videos/hero+mobile.mp4";
import LOGO from "../../assets/images/Rapsody logo.png";
import MusicSlider from "../../components/MusicSlider/MusicSlider";
import { isMobile } from "../../constants";

import SLIDE1 from "../../assets/images/acapella/Acapella album img2.png";
import SLIDE2 from "../../assets/images/acapella/VINYL_FRONT.png";
import SLIDE3 from "../../assets/images/acapella/white tee img.png";
import SLIDE4 from "../../assets/images/acapella/PDC hoodie.png";

// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function isSafari() {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') === -1 && navigator.userAgent.indexOf('FxiOS') === -1;
}

const VideoPlayer = styled.video``;

const LogoImg = styled.img`
    // filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(31deg) brightness(104%) contrast(101%);
`;

const sliderData = [
    {
        img: SLIDE1,
        title: "Enjoy. Have Fun. Please Do Remix",
        link: "https://drive.google.com/drive/u/1/folders/1_45oV-me21wpC2fzQZ2NeavKcutcU4IG",
    },
    {
        img: SLIDE2,
        title: "PLEASE DON'T CRY - ACAPELLA VINYL 2LP",
        link: "https://rapsody.rosecityworks.com/products/please-do-remix-lp",
    },
    {
        img: SLIDE3,
        title: "DEAR LOVED ONE - WHITE",
        link: "https://rapsody.rosecityworks.com/products/dear-loved-one-tee-white",
    },
    {
        img: SLIDE4,
        title: "PLEASE DONT CRY HOODIE",
        link: "https://rapsody.rosecityworks.com/products/please-dont-cry-limited-edition-custom-hoodie",
    },
];

const Acapella = () => {
    const videoRef: any = useRef(null);

    // const showSwal = () => {
    //   Swal.fire({
    //     // title: 'Error!',
    //     title: 'Subscribe for Updates',
    //     input: "email",
    //     inputPlaceholder: "Enter your email address",
    //     inputAttributes: {
    //       autocapitalize: "off"
    //     },
    //     confirmButtonColor: "#000000",
    //     confirmButtonText: "Submit",
    //     showLoaderOnConfirm: true,
    //     customClass: {
    //       input: "font-lucida-grande"
    //     },
    //     preConfirm: async (email) => {
    //       try {
    //         const data = {
    //           Email: email,
    //         };
    //         const response = await sendData(data);
  
    //         return response;
    //       } catch (error) {
    //         Swal.showValidationMessage(`
    //           Request Failed: ${error}
    //         `);
    //       }
    //     },
    //     willOpen: () => {
    //       scrollTop();
    //     },
    //     willClose: () => {
    //       scrollTop();
    //     },
    //     didOpen: function () {
    //       var offset = document.body.scrollTop;
    //       document.body.style.top = (offset * -1) + 'px';
    //       document.body.classList.add('modal--opened');
    //     },
    //     didClose: function () {
    //       var offset = parseInt(document.body.style.top, 10);
    //       document.body.classList.remove('modal--opened');
    //       document.body.scrollTop = (offset * -1);
    //     }
    //   })
    // }
  
    // function sendData(data: any) {
    //   Swal.fire({
    //     title: 'SUBMITTING EMAIL',
    //     willOpen: () => {
    //       scrollTop();
    //     },
    //     willClose: () => {
    //       scrollTop();
    //     }
    //   });
    //   Swal.showLoading();
  
    //   $.ajax({
    //     url: "https://script.google.com/macros/s/AKfycbw1tapQpeJCuHkunzMCHa9orKqh3cNYLqSZ1Eyz-kEdx6Jih5rWLJdkZZlZrY8Y8Xq9/exec",
    //     type: "POST",
    //     data: data
    //   }).done(function() {
    //     Swal.fire({
    //       title: 'SUCCESS',
    //       text: 'THANK YOU FOR SUBMITTING',
    //       icon: 'success',
    //       allowEscapeKey: false,
    //       confirmButtonColor: '#000000',
    //       willOpen: () => {
    //         scrollTop();
    //       },
    //       willClose: () => {
    //         scrollTop();
    //       },
    //       didOpen: function () {
    //         var offset = document.body.scrollTop;
    //         document.body.style.top = (offset * -1) + 'px';
    //         document.body.classList.add('modal--opened');
    //       },
    //       didClose: function () {
    //         var offset = parseInt(document.body.style.top, 10);
    //         document.body.classList.remove('modal--opened');
    //         document.body.scrollTop = (offset * -1);
    //       }
    //     });
    //   });
    // }
  
    // function scrollTop() {
    //   window.scrollTo({ top: 0, behavior: 'auto' });
    // }
  
    // setTimeout(function() {
    //     const videoPlayer = videoRef.current as HTMLVideoElement;

    //     if (videoPlayer !== null)
    //         videoPlayer.play();
        
    //     showSwal();
    //     scrollTop();
    // }, 100);

    return (
        <Box
            className={`relative flex flex-col lg:flex-row justify-center items-center ${iOS() ? isSafari() ? "h-[95vh]" : "h-[86vh]" : "h-[93vh]"} xs:h-screen sm:h-screen w-full overflow-hidden bg-[#FFFFFF]`}
        >
            <Box className={`relative flex justify-center h-full w-full`}>
                <VideoPlayer
                    id="video_player"
                    ref={videoRef}
                    preload="metadata"
                    playsInline
                    autoPlay
                    loop
                    muted
                    poster={isMobile ? POSTER : ""}
                    src={isMobile ? VIDEO_M : VIDEO_D}
                    className="h-full w-screen object-cover object-bottom outline-none"
                ></VideoPlayer>
            </Box>
            <Box className="absolute flex flex-col text-white justify-center items-center">
                <LogoImg src={LOGO} className="w-40 md:w-60 md:mt-8" />
                <h1 className="mt-5 text-2xl md:text-3xl text-center">PLEASE DON'T CRY<br/>(ACAPELLA)</h1>
                <MusicSlider data={sliderData} />
            </Box>
        </Box>
    );
};

export default Acapella;
