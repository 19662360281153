import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home, Home2, Album, Merch, Tour, Acapella } from '../app/index';
import PageNotFound from '../app/PageNotFound/PageNotFound';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/album" element={<Album />} />
        <Route path="/merch" element={<Merch />} />
        <Route path="/tour" element={<Tour />} />
        <Route path="/lovedones" element={<Home2 />} />
        <Route path="/acapella" element={<Acapella />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
