import { RoutePattern } from "../../routes/RoutePattern";

export const data = [
  {
    name: "Home",
    to: "/",
    isLinkOut: false,
  },
  {
    name: "Please Don<span class='font-bebas'>'</span>t Cry",
    to: RoutePattern.ALBUM,
    isLinkOut: false,
  },
  {
    name: "Merch",
    to: "https://rapsody.rosecityworks.com/",
    isLinkOut: true,
  },
  {
    name: "Tour",
    to: RoutePattern.TOUR,
    isLinkOut: false,
  },
];
